import "swiper/swiper.min.css";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import { serverLink } from "../../Resources/Url";
import ready from "../../Resources/ready-to-contact.jpg";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import { Link } from "react-router-dom";

const searchBarStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "20px",
};

const inputStyle = {
  padding: "8px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginRight: "10px",
  width: "200px",
};

const buttonStyle = {
  padding: "8px 12px",
  backgroundColor: "#008435",
  color: "white",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};
const Programmes = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [programme_, setProgramme_] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const [programmes] = useState(
    typeof props?.HomePageData.programmes !== "undefined"
      ? arrayShuffle(props?.HomePageData.programmes)
      : []
  );

  const filteredProgrammes_ = programmes
    .filter((x) => x.IsActive === Number(1))
    .slice(0, 4);

  const onViewMore = () => {
    setProgramme_(programmes);
  };

  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = programme_.filter((item) =>
      item.Title.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredResults(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
      setProgramme_(filteredProgrammes_);
    }, 500);
  }, []);

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <ErrorBoundary>
          <section
            className="banner-inner-sec"
            style={{
              backgroundImage: `url(${ready})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          >
            <div className="banner-table">
              <div className="banner-table-cell">
                <div className="container">
                  <div className="banner-inner-content">
                    <h2 className="banner-inner-title">Programmes</h2>
                    <ul className="xs-breadcumb">
                      <li>
                        <a href="index.html"> Home / </a> Programmes
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="service-sec section-padding">
            <div class="container">
              <div className="section-title-item">
                <h2 className="section-title">
                  <span className="xs-title">Our Programmes</span>
                  Programmes Archive
                </h2>
              </div>

              <div className="search-bar" style={searchBarStyle}>
                <input
                  type="text"
                  placeholder="Search programme..."
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  style={inputStyle}
                />
                <button
                  onClick={() => {
                    setSearchQuery("");
                    setFilteredResults([]);
                  }}
                  style={buttonStyle}
                >
                  Clear Search
                </button>
              </div>

              <div className="row">
                {(searchQuery.length > 0 ? filteredResults : programme_).map(
                  (item, index) => (
                    <>
                      <div
                        className="col-lg-3 col-sm-6 wow fadeInUp"
                        data-wow-duration="1.5s"
                        data-wow-delay="300ms"
                      >
                        <div className="single-service">
                          <Link to={`/programme/${item.Slug}`}>
                            <div className="service-img">
                              <img src={`${item.FrontImage}`} alt="" />
                              <i className="icon-Our_service_1"></i>
                            </div>
                            <h3 className="xs-service-title">
                              <a href="#">{item.Title}</a>{" "}
                            </h3>
                            <p>{item.Description}</p>

                            <Link
                              to={`/programme/${item.Slug}`}
                              className="readMore"
                            >
                              Read more{" "}
                              <i className="icon icon-arrow-right"></i>{" "}
                            </Link>
                          </Link>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="load-more-btn">
                    <a
                      className="xs-btn fill"
                      onClick={onViewMore}
                      style={{
                        backgroundColor: "#008435",
                        color: "white",
                      }}
                    >
                      View More
                    </a>
                  </div>
                </div>
              </div>
              <p></p>
            </div>
          </section>
        </ErrorBoundary>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Programmes);
