import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import {
    HomePageContentsReducer,
    LoginDetailsReducer,
    PermissionDetailsReducer,
} from "../DetailsReducer/DetailsReducer";
import { shortCode } from "../../Resources/Url";

const rootReducer = combineReducers({
    LoginDetails: LoginDetailsReducer,
    PermissionDetails: PermissionDetailsReducer,
    HomePageContents: HomePageContentsReducer,
});

const persistConfig = {
    key: shortCode.toLowerCase(),
    storage,
};

export default persistReducer(persistConfig, rootReducer);
