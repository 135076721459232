import React, { useState } from "react";
import { connect } from "react-redux";
import { setHomePageContents } from "../../../Actions/Actions";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import logo from "../../../Resources/citizens_logo_white.png";
import { Link } from "react-router-dom";
import arrayShuffle from "array-shuffle";

const Footer = (props) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const [programmes] = useState(
    typeof props?.HomePageData.programmes !== "undefined"
      ? arrayShuffle(props?.HomePageData.programmes)
      : []
  );

  const filteredProjects = programmes
    .filter((x) => x.IsActive === Number(1))
    .slice(0, 6);

  return (
    <ErrorBoundary>
      <footer class="xs-footer-sec">
        <div class="container">
          <div class="footer-top-item">
            <div class="xs-back-to-top">
              <a href="#" class="BackTo">
                <i class="fa fa-angle-double-up"></i>{" "}
              </a>
            </div>
          </div>

          <div class="footer-item">
            <div class="row">
              <div class="col-lg-3">
                <div class="widgets">
                  <div class="footer-logo">
                    <Link to="/">
                      <img src={logo} alt="" />{" "}
                    </Link>
                  </div>
                  <p>
                    Our vision is to create happiness and prosperity by building trust and
                    transforming Nigerian neighborhoods into Personal Economic Communities (PEC)
                    for wealth creation. We value collaboration, accountability, saving,
                    self-improvement, and data-driven systems. PEC supports citizens
                    with training, mentorship, and digital market access through various hubs.
                  </p>
                  <ul class="footer-social">
                    <li>
                      <a
                        href="https://m.facebook.com/people/NALDA-Nigeria/100069358503080/"
                        target="_blank"
                      >
                        <i class="fa fa-facebook-f"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://x.com/NALDA_NG?t=5RDBU2v3RLUSYdtSYu4fDw&s=09"
                        target="_blank"
                      >
                        <i class="fa fa-twitter"></i>{" "}
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://www.instagram.com/nalda_ng/?igshid=gpwiob6hqh0r"
                        target="_blank"
                      >
                        <i class="fa fa-instagram"></i>{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3">
                <div class="widgets">
                  <h3 class="widget-title">Useful Links</h3>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>

                    <li>
                      <Link to="/market">Market</Link>
                    </li>
                    <li>
                      <Link to="/programme">Programmes</Link>
                    </li>
                    <li>
                      <Link to="/news">News</Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3">
                <div class="widgets">
                  <h3 class="widget-title">Our Programmes</h3>
                  <ul class="service-link">
                    {programmes &&
                      Object.keys(programmes).length > 0 &&
                      programmes.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link href="#">{item.Title}</Link>
                          </li>
                        );
                      })}{" "}
                    })}
                  </ul>
                </div>
              </div>
              <div class="col-md-3">
                <div class="widgets">
                  <h3 class="widget-title">Contact Us</h3>
                  <ul class="footer-contact-list">
                    <li>
                      <i class="icon icon-map-marker2"></i>
                      Asokoro, Abuja Nigeria
                    </li>
                    <li>
                      <i class="icon icon-phone3"></i>
                      0700-CITIZENSCLUB
                    </li>
                    <li>
                      <i class="icon icon-envelope"></i>
                      support@citizensofnigeria.ng
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="copyright-content">
            <div class="row">
              <div class="col-md-6">
                <p>Copyright {currentYear}, The Citizens Club. All Rights Reserved.</p>
              </div>
              <div class="col-md-6">
                <ul class="footer-bottom-menu">
                  <li>
                    <Link to="/">Terms of Service </Link>
                  </li>
                  <li>
                    <Link to="/">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageData: (p) => {
      dispatch(setHomePageContents(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
