import "swiper/swiper.min.css";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import { serverLink } from "../../Resources/Url";
import ready from "../../Resources/ready-to-contact.jpg";
import singlePost from "../../Resources/single-post-img-v2.jpg";
import singlePost3 from "../../Resources/single-post-img-3.jpg";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import LoadingProduct from "../Shared/Loaders/LoadingProduct/LoadingProduct";
import Preloader from "../Shared/Preloader/Preloader";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";

const searchBarStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "20px",
};

const inputStyle = {
  padding: "8px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginRight: "10px",
  width: "200px",
};

const buttonStyle = {
  padding: "8px 12px",
  backgroundColor: "#008435",
  color: "white",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};
const SingleProgramme = (props) => {
  const lastCharacter = Number(window.location.pathname.match(/(\d+)$/)[0]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [programme, setProgramme] = useState([]);
  const [programme_, setProgramme_] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [projects_, setProjects_] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const [projects] = useState(
    typeof props?.HomePageData.projects !== "undefined"
      ? arrayShuffle(props?.HomePageData.projects)
      : []
  );

  const filteredProjects_ = projects.filter(
    (x) => x.IsActive === Number(1) && x.ProgrammeId === Number(lastCharacter)
  );

  const filteredProjects = projects
    .filter(
      (x) => x.IsActive === Number(1) && x.ProgrammeId === Number(lastCharacter)
    )
    .slice(0, 20);

  const onViewMore = () => {
    setProjects_(filteredProjects_);
  };

  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = projects_.filter((item) =>
      item.Title.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredResults(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
      setProjects_(filteredProjects);
    }, 1000);
  }, []);

  const sendData = {
    ProgrammeId: Number(lastCharacter),
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchProgrammes = async () => {
      try {
        const response = await axios.post(
          `${serverLink}shared/programmes`,
          sendData
        );
        setProgramme(response.data);
      } catch (error) {
        navigate("/");
        console.error("Error fetching product:", error);
      }
    };

    fetchProgrammes().then((r) => {});
  }, []);

  if (Object.keys(programme).length === 0) {
    return <HomeContentLoader />;
  }

  return (
    <ErrorBoundary>
      <section
        className="banner-inner-sec"
        style={{
          backgroundImage: `url(${ready})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="banner-table">
          <div className="banner-table-cell">
            <div className="container">
              <div className="banner-inner-content">
                <h2 className="banner-inner-title">{programme.Title}</h2>
                <ul className="xs-breadcumb">
                  <li>
                    <a href="index.html"> Home / Programmes </a> /{" "}
                    {programme.Title}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="single-service-inner-v2-sec service-inner-sec section-padding">
        <div className="container">
          <div className="main-single-service-v2">
            <div className="single-service-post-content">
              <img src={`${programme.MainImage}`} alt="" />
              <i className="icon-Our_service_1"></i>

              <h2 className="column-title">{programme.Title}</h2>
              <p>{programme.LongDescription}</p>
            </div>

            {filteredProjects_ && Object.keys(filteredProjects_).length > 0 && (
              <section className="service-sec1 section-padding">
                <div className="container">
                  <div className="section-title-item">
                    <h2 className="column-title">
                      {" "}
                      Projects Included in the Programme
                    </h2>
                  </div>

                  <div className="search-bar" style={searchBarStyle}>
                    <input
                      type="text"
                      placeholder="Search project..."
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        handleSearch(e.target.value);
                      }}
                      style={inputStyle}
                    />
                    <button
                      onClick={() => {
                        setSearchQuery("");
                        setFilteredResults([]);
                      }}
                      style={buttonStyle}
                    >
                      Clear Search
                    </button>
                  </div>

                  <div className="row">
                    {(searchQuery.length > 0 ? filteredResults : projects_).map(
                      (item, index) => (
                        <>
                          <>
                            <div
                              className="col-lg-3 col-sm-6 wow fadeInUp"
                              data-wow-duration="1.5s"
                              data-wow-delay="300ms"
                            >
                              <div className="single-service">
                                <Link to={`/projects/${item.Slug}`}>
                                  <div className="service-img">
                                    <img
                                      //         src={`${serverLink}public/uploads/projects/${item.FrontImage}`}
                                      src={`${item.FrontImage}`}
                                      alt=""
                                    />
                                    <i className="icon-Our_service_1"></i>
                                  </div>
                                  <h3 className="xs-service-title">
                                    <a href="#">{item.Title}</a>{" "}
                                  </h3>
                                  <p>{item.Description}</p>

                                  <Link
                                    to={`/projects/${item.Slug}`}
                                    className="readMore"
                                  >
                                    Read more{" "}
                                    <i className="icon icon-arrow-right"></i>{" "}
                                  </Link>
                                </Link>
                              </div>
                            </div>
                          </>
                        </>
                      )
                    )}
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="load-more-btn">
                        <a
                          className="xs-btn fill"
                          onClick={onViewMore}
                          style={{
                            backgroundColor: "#008435",
                            color: "white",
                          }}
                        >
                          View More
                        </a>
                      </div>
                    </div>
                  </div>
                  <p></p>
                </div>
              </section>
            )}
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleProgramme);
