import "swiper/swiper.min.css";
import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { setHomePageContents } from "../../../Actions/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax, Pagination, Navigation } from "swiper";
import SwiperCore, { Autoplay } from "swiper";
import { serverLink } from "../../../Resources/Url";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { Link } from "react-router-dom";

SwiperCore.use([Autoplay, Pagination, Navigation]);
const Slider = (props) => {
  const swiperRef = React.useRef(null);
  const [sliders] = useState(
    typeof props.HomePageData.slider !== "undefined"
      ? props.HomePageData.slider
      : []
  );

  // Function to handle "Next" button click
  const handleNext = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  // Function to handle "Previous" button click
  const handlePrev = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <ErrorBoundary>
      <section className="xs-banner-sec owl-carousel banner-slider owl-loaded owl-drag">
        <Swiper
          ref={swiperRef} // Attach the Swiper instance to the ref
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false} // Disable the default navigation
          className="mySwiper"
        >
          {sliders &&
            sliders.map((item, index) => {
              return (
                <SwiperSlide>
                  <div
                    key={index}
                    className="banner-slider-item banner-item1"
                    style={{
                      backgroundImage: `url(${item.Image})`,
                    }}
                  >
                    <div className="slider-table">
                      <div className="slider-table-cell">
                        <div
                          className="container"
                          style={{
                            alignContent: "center",
                          }}
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div className="banner-content text-center">
                                {item.Url && (
                                  <div
                                    className="xs-btn-wraper"
                                    style={{
                                      marginTop: "420px",
                                    }}
                                  >
                                    <a
                                      href={`https://www.nalda.gov.ng/${item.Url}`}
                                      target="_blank"
                                      className="xs-btn fill"
                                      style={{
                                        color: "white",
                                      }}
                                    >
                                      Read more
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="owl-nav">
                    <div className="owl-prev">
                      <i
                        className="icon icon-chevron-left"
                        onClick={handlePrev}
                      ></i>
                    </div>
                    <div className="owl-next">
                      <i
                        className="icon icon-chevron-right"
                        onClick={handleNext}
                      ></i>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
        <section className="promo-area-sec">
          <div className="container">
            <div className="promo-content-item">
              <div className="row">
                <div
                  className="col-md-4 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="300ms"
                >
                  <div className="single-promo-content">
                    <i className="icon-service_1"></i>
                    <h3 className="xs-service-title">Citizens Club Empowerment Scheme</h3>
                    <p>
                      Aims to create well-informed and empowered members through various educational
                      and training programs, enhancing their knowledge and skills for better personal and community development
                    </p>
                    <a href="#" className="xs-btn sm-btn">
                      Learn More
                    </a>
                  </div>
                </div>
                <div
                  className="col-md-4 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="400ms"
                >
                  <div className="single-promo-content">
                    <i className="icon-money-3"></i>
                    <h3 className="xs-service-title">
                      Citizens Club Savings
                    </h3>
                    <p>
                      Seeks to increase access to financial services for members, particularly those
                      in financially excluded groups, by providing tailored savings plans and financial literacy programs to encourage regular saving and smart investing.
                    </p>
                    <a href="#" className="xs-btn sm-btn">
                      Learn More
                    </a>
                  </div>
                </div>
                <div
                  className="col-md-4 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="500ms"
                >
                  <div className="single-promo-content">
                    <i className="icon-Our_service_2"></i>
                    <h3 className="xs-service-title">
                      The Citizens Club E-Market Platform
                    </h3>
                    <p>
                      Designed to incubate and foster a growing number of supported and networked indigenous businesses by offering a
                      digital marketplace, resources for business development, and opportunities for collaboration and market expansion.
                    </p>
                    <a href="#" className="xs-btn sm-btn">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageData: (p) => {
      dispatch(setHomePageContents(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
