import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setHomePageContents } from "../../../Actions/Actions";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { serverLink } from "../../../Resources/Url";

const TopHeader = (props) => {
  return (
    <ErrorBoundary>
      <section className="xs-header-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div className="header-top-info">
                <ul>
                  <li>
                    <i className="icon icon-map-marker2"></i> Asokoro, Abuja Nigeria
                  </li>
                  <li>
                    <i className="icon icon-envelope"></i> support@citizensofnigeria.ng
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 align-self-center col-md-4">
              <div className="header-top-social">
                <ul>
                  <li>
                    <a
                      href="https://web.facebook.com/clubnigeria1/"
                      target="_blank"
                    >
                      <i className="fa fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://x.com/CitizensClubNG"
                      target="_blank"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/citizensclubng/"
                      target="_blank"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageData: (p) => {
      dispatch(setHomePageContents(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
