import "swiper/swiper.min.css";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import { serverLink } from "../../Resources/Url";
import ready from "../../Resources/ready-to-contact.jpg";
import { Link } from "react-router-dom";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import FooterContact from "../Shared/FooterContact/FooterContact";

const searchBarStyle = {
  display: "flex",
  alignItems: "center",
  marginBottom: "20px",
};

const inputStyle = {
  padding: "8px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginRight: "10px",
  width: "200px",
};

const buttonStyle = {
  padding: "8px 12px",
  backgroundColor: "#008435",
  color: "white",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

const News = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [news_, setNews_] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const [news] = useState(
    typeof props?.HomePageData.news !== "undefined"
      ? arrayShuffle(props?.HomePageData.news)
      : []
  );

  const filteredNews = news.filter((x) => x.IsActive === Number(1)).slice(0, 6);

  const onViewMore = () => {
    setNews_(news);
  };

  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = news_.filter((item) =>
      item.Title.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredResults(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
      setNews_(filteredNews);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <ErrorBoundary>
          <section
            className="banner-inner-sec"
            style={{
              backgroundImage: `url(${ready})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          >
            <div className="banner-table">
              <div className="banner-table-cell">
                <div className="container">
                  <div className="banner-inner-content">
                    <h2 className="banner-inner-title">News</h2>
                    <ul className="xs-breadcumb">
                      <li>
                        <a href="index.html"> Home / </a> News
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="latest-news-sec section-padding">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div className="search-bar" style={searchBarStyle}>
                    <input
                      type="text"
                      placeholder="Search news..."
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        handleSearch(e.target.value);
                      }}
                      style={inputStyle}
                    />
                    <button
                      onClick={() => {
                        setSearchQuery("");
                        setFilteredResults([]);
                      }}
                      style={buttonStyle}
                    >
                      Clear Search
                    </button>
                  </div>

                  <div class="row">
                    {(searchQuery.length > 0 ? filteredResults : news).map(
                      (item, index) => (
                        <>
                          <div
                            className="col-md-4 wow fadeInUp"
                            data-wow-duration="1.5s"
                            data-wow-delay="400ms"
                            key={index}
                          >
                            <div className="single-latest-news">
                              <Link to={`/news/${item.Slug}`}>
                                <div className="latest-news-img">
                                  <a href="">
                                    <img src={`${item.CoverImage}`} alt="" />
                                  </a>
                                </div>
                                <div className="single-news-content">
                                  <span className="date-info">
                                    {item.CreatedDate}
                                  </span>
                                  <h3 className="xs-post-title">
                                    <a href="#">{item.Title}</a>
                                  </h3>
                                  <p>{item.ParagraphOne}</p>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </>
                      )
                    )}
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="load-more-btn">
                        <a
                          className="xs-btn fill"
                          onClick={onViewMore}
                          style={{
                            backgroundColor: "#008435",
                            color: "white",
                          }}
                        >
                          View More
                        </a>
                      </div>
                    </div>
                  </div>
                  <p></p>
                </div>
              </div>
            </div>
          </section>
          <FooterContact />
        </ErrorBoundary>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
