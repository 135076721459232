// import React, { useState } from "react";
// import { connect } from "react-redux";
// import { setHomePageContents } from "../../../Actions/Actions";
// import { Link, useLocation } from "react-router-dom";
// import { isMobile } from "react-device-detect";
// import logo from "../../../Resources/citizens_logo.png";
// import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
//
// const Header = (props) => {
//   const location = useLocation();
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [activeSubMenu, setActiveSubMenu] = useState(null);
//
//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };
//
//   const closeToggleMenu = () => {
//     setIsMenuOpen(false);
//     setActiveSubMenu(null); // Close all submenus
//   };
//
//   const navMenusWrapperClassName = `nav-menus-wrapper clearfix ${
//       isMenuOpen ? "nav-menus-wrapper-open" : ""
//   }`;
//
//   const handleMenuItemClick = () => {
//     setIsMenuOpen(false);
//     setActiveSubMenu(null); // Close all submenus
//   };
//
//   const handleMouseEnter = (menuName) => {
//     setActiveSubMenu(menuName);
//   };
//
//   const handleMouseLeave = () => {
//     setActiveSubMenu(null);
//   };
//
//   const listItemStyle = {
//     position: "relative", // To position the submenu relative to the parent item
//   };
//
//   const submenuStyle = (menuName) => ({
//     display: activeSubMenu === menuName ? "block" : "none", // Show or hide based on state
//     position: "absolute", // Position submenu absolutely
//     top: "100%", // Position below the parent item
//     left: 0, // Align with the parent item
//   });
//
//   const renderMenuItems = () => (
//       <>
//         <li className="active">
//           <Link to="/" onClick={handleMenuItemClick}>
//             Home
//           </Link>
//         </li>
//         <li>
//           <Link to="/programme" onClick={handleMenuItemClick}>
//             Our Programmes
//           </Link>
//         </li>
//
//         <li
//             className="focus"
//             style={listItemStyle}
//             onMouseEnter={() => handleMouseEnter("whatWeDo")}
//             onMouseLeave={handleMouseLeave}
//         >
//           <Link to="#">
//             What We Do
//             <span className="submenu-indicator">
//                             <span className="submenu-indicator-chevron"></span>
//                           </span>
//           </Link>
//           <ul
//               className="nav-dropdown nav-submenu"
//               style={submenuStyle("whatWeDo")}
//           >
//             <li>
//               <Link to="/citizens-club-empower-scheme">
//                 Citizens Club Empowerment Scheme
//               </Link>
//             </li>
//             <li>
//               <Link to="/citizens-club-savings">
//                 Citizens Club Savings
//               </Link>
//             </li>
//             <li>
//               <Link to="/the-citizens-club-e-market-platform">
//                 The Citizens Club E-Market Platform
//               </Link>
//             </li>
//             <li>
//               <Link to="/news-and-polls">News and Polls</Link>
//             </li>
//           </ul>
//         </li>
//
//         <li
//             className="focus"
//             style={listItemStyle}
//             onMouseEnter={() => handleMouseEnter("whoWeAre")}
//             onMouseLeave={handleMouseLeave}
//         >
//           <Link to="#">
//             Who We Are
//             <span className="submenu-indicator">
//                             <span className="submenu-indicator-chevron"></span>
//                           </span>
//           </Link>
//           <ul
//               className="nav-dropdown nav-submenu"
//               style={submenuStyle("whoWeAre")}
//           >
//             <li>
//               <Link to="/who-can-join">Who can Join</Link>
//             </li>
//             <li>
//               <Link to="/how-to-join">How to Join</Link>
//             </li>
//             <li>
//               <Link to="/membership-types">Membership Types</Link>
//             </li>
//             <li>
//               <Link to="/news-and-polls">Membership Strength</Link>
//             </li>
//             <li>
//               <Link to="/register">Apply Now</Link>
//             </li>
//           </ul>
//         </li>
//
//         <li>
//           <Link to="/contact" onClick={handleMenuItemClick}>
//             Contact
//           </Link>
//         </li>
//       </>
//   );
//
//   return (
//       <ErrorBoundary>
//         <header className="xs-header-nav">
//           <div className="container">
//             <div className="row menu-item">
//               <div className="col-lg-12">
//                 <nav
//                     id="navigation1"
//                     className={`navigation header-nav clearfix ${isMobile ? 'navigation-portrait' : ''}`}
//                 >
//                   <div className="nav-header">
//                     <Link to="/" className="mobile-logo">
//                       <img src={logo} alt="" />
//                     </Link>
//                     <div className="nav-toggle" onClick={toggleMenu}></div>
//                   </div>
//                   <div
//                       className={navMenusWrapperClassName}
//                       style={{ transitionProperty: "none" }}
//                   >
//                     {isMobile && (
//                         <span
//                             className="nav-menus-wrapper-close-button"
//                             onClick={closeToggleMenu}
//                         >
//                       ✕
//                     </span>
//                     )}
//                     <ul className="nav-menu">
//                       {renderMenuItems()}
//                     </ul>
//                     <div className="header-nav-right-info align-to-right">
//                       <label>
//                         <i className="icon icon-phone3"></i> {isMobile ? '0703 326 4358' : '0700-CITIZENSCLUB'}
//                       </label>
//                     </div>
//                   </div>
//                   {isMobile && (
//                       <div
//                           className="nav-overlay-panel"
//                           style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", display: "none" }}
//                       ></div>
//                   )}
//                 </nav>
//               </div>
//             </div>
//           </div>
//         </header>
//       </ErrorBoundary>
//   );
// };
//
// const mapStateToProps = (state) => {
//   return {
//     HomePageData: state.HomePageContents,
//     UserProfile: state.LoginDetails,
//   };
// };
//
// const mapDispatchToProps = (dispatch) => {
//   return {
//     setOnHomePageData: (p) => {
//       dispatch(setHomePageContents(p));
//     },
//   };
// };
//
// export default connect(mapStateToProps, mapDispatchToProps)(Header);





import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setHomePageContents } from "../../../Actions/Actions";
import { Link, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import logo from "../../../Resources/citizens_logo.png";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import arrayShuffle from "array-shuffle";

const Header = (props) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const menuRef = useRef(null);

  const [whatwedo] = useState(
      typeof props?.HomePageData.whatwedo !== "undefined"
          ? arrayShuffle(props?.HomePageData.whatwedo)
          : []
  );

  console.log("whatwedo", whatwedo)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeToggleMenu = () => {
    setIsMenuOpen(false);
    setActiveSubMenu(null); // Close all submenus
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
    setActiveSubMenu(null); // Close all submenus
  };

  const handleMouseEnter = (menuName) => {
    setActiveSubMenu(menuName);
  };

  const handleMouseLeave = () => {
    setActiveSubMenu(null);
  };

  const handleTouchOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      closeToggleMenu();
    }
  };

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('touchstart', handleTouchOutside);
      return () => {
        document.removeEventListener('touchstart', handleTouchOutside);
      };
    }
  }, [isMobile]);

  // Define navMenusWrapperClassName here
  const navMenusWrapperClassName = `nav-menus-wrapper clearfix ${
      isMenuOpen ? "nav-menus-wrapper-open" : ""
  }`;

  const listItemStyle = {
    position: "relative", // To position the submenu relative to the parent item
  };

  const submenuStyle = (menuName) => ({
    display: activeSubMenu === menuName ? "block" : "none", // Show or hide based on state
    position: "absolute", // Position submenu absolutely
    top: "100%", // Position below the parent item
    left: 0, // Align with the parent item
  });

  const renderMenuItems = () => (
      <>
        <li className="active">
          <Link to="/" onClick={handleMenuItemClick}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/market" onClick={handleMenuItemClick}>
            Market
          </Link>
        </li>
        <li>
          <Link to="/programme" onClick={handleMenuItemClick}>
            Our Programmes
          </Link>
        </li>

        <li
            className="focus"
            style={listItemStyle}
            onMouseEnter={() => handleMouseEnter("whatWeDo")}
            onMouseLeave={handleMouseLeave}
        >
          <Link to="#">
            What We Do
            <span className="submenu-indicator">
                            <span className="submenu-indicator-chevron"></span>
                          </span>
          </Link>
          <ul
              className="nav-dropdown nav-submenu"
              style={submenuStyle("whatWeDo")}
          >



            {Array.isArray(whatwedo) && whatwedo.length > 0 && whatwedo.map((item, index) => (
                <li key={index}>
                  <Link to={`/market/${item.Link}`}>
                    {item.Title}
                  </Link>
                </li>
            ))}



            {/*<li>*/}
            {/*  <Link to="/citizens-club-empower-scheme">*/}
            {/*    Citizens Club Empowerment Scheme*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to="/citizens-club-savings">*/}
            {/*    Citizens Club Savings*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to="/the-citizens-club-e-market-platform">*/}
            {/*    The Citizens Club E-Market Platform*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to="/news-and-polls">News and Polls</Link>*/}
            {/*</li>*/}
          </ul>
        </li>

        <li
            className="focus"
            style={listItemStyle}
            onMouseEnter={() => handleMouseEnter("whoWeAre")}
            onMouseLeave={handleMouseLeave}
        >
          <Link to="#">
            Who We Are
            <span className="submenu-indicator">
                            <span className="submenu-indicator-chevron"></span>
                          </span>
          </Link>
          <ul
              className="nav-dropdown nav-submenu"
              style={submenuStyle("whoWeAre")}
          >
            <li>
              <Link to="/who-can-join">Who can Join</Link>
            </li>
            <li>
              <Link to="/how-to-join">How to Join</Link>
            </li>
            <li>
              <Link to="/membership-types">Membership Types</Link>
            </li>
            <li>
              <Link to="/news-and-polls">Membership Strength</Link>
            </li>
            <li>
              <Link to="/register">Apply Now</Link>
            </li>
          </ul>
        </li>
      </>
  );

  return (
      <ErrorBoundary>
        <header className="xs-header-nav">
          <div className="container">
            <div className="row menu-item">
              <div className="col-lg-12">
                <nav
                    id="navigation1"
                    className={`navigation header-nav clearfix ${isMobile ? 'navigation-portrait' : ''}`}
                    ref={menuRef} // Attach ref to the menu
                >
                  <div className="nav-header">
                    <Link to="/" className="mobile-logo">
                      <img src={logo} alt="" />
                    </Link>
                    <div className="nav-toggle" onClick={toggleMenu}></div>
                  </div>
                  <div
                      className={navMenusWrapperClassName}
                      style={{ transitionProperty: "none" }}
                  >
                    {isMobile && (
                        <span
                            className="nav-menus-wrapper-close-button"
                            onClick={closeToggleMenu}
                        >
                      ✕
                    </span>
                    )}
                    <ul className="nav-menu">
                      {renderMenuItems()}
                    </ul>
                    <div className="header-nav-right-info align-to-right">
                      <label>
                        <i className="icon icon-phone3"></i> {isMobile ? '0703 326 4358' : '0700-CITIZENSCLUB'}
                      </label>
                    </div>
                  </div>
                  {isMobile && (
                      <div
                          className="nav-overlay-panel"
                          style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", display: "none" }}
                      ></div>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </header>
      </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageData: (p) => {
      dispatch(setHomePageContents(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
