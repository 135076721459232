import React from "react";
// import loaderLogo from "../../../../Resources/preloader-icon.svg";

function LoadingProduct() {
  const spinnerContainerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f5f7f9",
    color: "black",
    fontSize: "16px",
    zIndex: "9999",
  };

  return (
    <div style={spinnerContainerStyle}>
      <div className="tp-preloader-logo">
        <div className="tp-preloader-circle">
          <svg
            width="190"
            height="190"
            viewBox="0 0 380 380"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              stroke="#D9D9D9"
              cx="190"
              cy="190"
              r="180"
              stroke-width="6"
              stroke-linecap="round"
            ></circle>
            <circle
              stroke="red"
              cx="190"
              cy="190"
              r="180"
              stroke-width="6"
              stroke-linecap="round"
            ></circle>
          </svg>
        </div>
        {/*<img src={loaderLogo} alt="" />*/}
        <p className="tp-preloader-subtitle">Loading product...</p>
      </div>
    </div>
  );
}

export default LoadingProduct;
