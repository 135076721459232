import { projectCode } from "../Resources/Url";

export const setLoginDetails = (p) => {
    return {
        type: `SET_${projectCode}_LOGIN_DETAILS`,
        payload: p,
    };
};

export const setCustomerIdentityDetails = (p) => {
    return {
        type: `SET_${projectCode}_CUSTOMER_IDENTITY_DETAILS`,
        payload: p,
    };
};

export const setPermissionDetails = (p) => {
    return {
        type: `SET_${projectCode}_PERMISSION_DETAILS`,
        payload: p,
    };
};

export const setHomePageContents = (p)=>{
    return{
        type: "SET_HOMEPAGE_CONTENTS",
        payload: p
    };
};

